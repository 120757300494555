import React, { useState, useEffect } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { RoughnessMipmapper } from "three/examples/jsm/utils/RoughnessMipmapper.js";

import * as THREE from "three";

import { useThree } from "react-three-fiber";

function Igloo({ position = [0, 0, 0], rotation = 0 }) {
  const [model, setModel] = useState();
  const { gl } = useThree();

  useEffect(() => {
    const loader = new GLTFLoader();
    const roughnessMipmapper = new RoughnessMipmapper(gl);

    loader.setPath("/models/igloo/").load(
      "scene.gltf",
      (model) => {
        const rootScene = model.scene;

        rootScene.scale.set(2.5, 2.5, 2.5);

        rootScene.rotateY(THREE.Math.degToRad(rotation));

        rootScene.position.x = position[0];
        rootScene.position.y = position[1];
        rootScene.position.z = position[2];

        // if the object can cast a shadow, let it cast and receive a shadow
        rootScene.traverse((child) => {
          if (child.isMesh) {
            roughnessMipmapper.generateMipmaps(child.material);
            child.receiveShadow = true;
          }
        });

        roughnessMipmapper.dispose();

        setModel(rootScene);
      },
      () => {},
      (error) => {
        console.error("GLTF loading error:", error);
      }
    );
  }, []);

  return (
    <mesh>
      {model && <primitive castShadow receiveShadow object={model}></primitive>}
    </mesh>
  );
}

export default Igloo;
