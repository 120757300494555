import React, { Suspense, useEffect, useState, useRef } from "react";

import * as THREE from "three";

import { Canvas, useThree } from "react-three-fiber";
import { OrbitControls, Text, Stars, Html } from "drei";
import { EffectComposer, Bloom } from "react-postprocessing";

import styled from "styled-components";

import Husky from "../Husky";
import Igloo from "../Igloo";

import Camera from "../Camera";

import OpenSansExtraBold from "../../assets/fonts/opensans-extrabold-webfont.woff";

const MailLinkStyles = styled.a`
  color: #ffffff;
  font-size: clamp(100%, 1rem + 4vw, 74px);
  font-weight: 800;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.91);
`;

function MainStage() {
  const [floorGeometry, setFloorGeometry] = useState();
  const { scene } = useThree();

  // const groupRef = useRef();

  useEffect(() => {
    const floorGeo = new THREE.ConeGeometry(23, 20, 32, 32, false);

    floorGeo.vertices.forEach((vert, _idx) => {
      const { x, y, z } = vert;

      vert.setY(THREE.Math.randFloat(0, 1) + y);
      vert.setX(THREE.Math.randFloat(0, 1) + x);
      vert.setZ(THREE.Math.randFloat(0, 1) + z);
    });

    floorGeo.computeVertexNormals();

    setFloorGeometry(floorGeo);
  }, []);

  useEffect(() => {
    // if (!groupRef.current) return;
    const group = scene.getObjectByName("main");
    const sensitivity = 0.05;
    const euler = new THREE.Euler().setFromQuaternion(group.quaternion);

    const animProps = {
      speed: 0.8,
      mouse: {
        x: 0,
        y: 0
      }
    };

    document.addEventListener("pointermove", (e) => {
      const mouseX = (e.x / window.innerWidth) * 2 - 1;
      const mouseY = (e.y / window.innerHeight) * 2 - 1;

      const { movementX, movementY } = e;

      // console.log(e);

      euler.x = mouseY * sensitivity;
      euler.y = mouseX * sensitivity;

      group.quaternion.setFromEuler(euler);

      // console.log({
      //   e,
      //   x: e.x,
      //   y: e.y,
      //   mouseY,
      //   mouseX
      // });
    });
  }, []);

  return (
    <group name="main" rotation={[0, 0, 0]} position={[0, 4, 0]}>
      <Suspense fallback={null}>
        <Text
          color={"#ffffff"}
          fontSize={2}
          position={[0, 12, 0]}
          font={OpenSansExtraBold}
        >
          baltic digital
        </Text>
        <Igloo position={[0, 0, -20]} />
        <Husky position={[0, 0, 0]} rotation={0} />

        {floorGeometry && (
          <mesh
            receiveShadow
            rotation={[-Math.PI, 0, 0]}
            position={[0, -10.5, -18]}
          >
            <primitive attach="geometry" object={floorGeometry}></primitive>
            <meshPhongMaterial
              attach="material"
              args={[
                {
                  flatShading: true,
                  shininess: 60,
                  reflectivity: 0.4,
                  specular: 0xffffff,
                  color: new THREE.Color("#B1F7FF"),
                  emissive: new THREE.Color("#2b383f")
                }
              ]}
            ></meshPhongMaterial>
          </mesh>
        )}
      </Suspense>
      <mesh position={[0, -10, 10]}>
        <sphereBufferGeometry args={[1, 32]} attach="geometry" />
        <meshBasicMaterial
          visible={false}
          color="red"
          attach="material"
          wireframe
        />
        <Html center>
          <MailLinkStyles href="mailto:hello@balticdigital.io">
            hello@balticdigital.io
          </MailLinkStyles>
        </Html>
      </mesh>
    </group>
  );
}

export default function World() {
  return (
    <Canvas shadowMap={true}>
      <directionalLight args={["#ffffff", 0.2]} castShadow />
      <EffectComposer>
        <hemisphereLight args={["#3F51A7", "#3779fc", 0.2]} />
        <pointLight args={["#3779fc", 0.2, 0, 2]} position={[30, -20, 0]} />
        <pointLight args={["#40FF9F", 0.4, 0, 2]} position={[30, -20, 20]} />

        <MainStage />

        <Bloom luminanceThreshold={0.8} />
        {/* <DepthOfField focusDistance={1} focalLength={5} bokehScale={2} /> */}
      </EffectComposer>
      <Stars
        radius={40} // Radius of the inner sphere (default=100)
        depth={75} // Depth of area where stars should fit (default=50)
        count={8000} // Amount of stars (default=5000)
        factor={6} // Size factor (default=4)
        saturation={1} // Saturation 0-1 (default=0)
        fade={true} // Faded dots (default=false)
      />
      <Camera lookAt={new THREE.Vector3(0, 0, 0)} position={[0, 0, 40]} />
      {/* <OrbitControls /> */}
    </Canvas>
  );
}
