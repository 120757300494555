import React, { useRef, useState, useEffect } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { RoughnessMipmapper } from "three/examples/jsm/utils/RoughnessMipmapper.js";
import { AnimationClip } from "three/src/animation/AnimationClip";
import gsap from "gsap";

import { motion as m } from "framer-motion";
import styled from "styled-components";

import * as THREE from "three";

import { Html } from "drei";
import { useFrame, useThree } from "react-three-fiber";

const Logo = styled(m.div)`
  width: 50px;
  height: 50px;
`;

const animations = [
  "Arm_Dog|Agression",
  "Arm_Dog|Attack_1",
  "Arm_Dog|Attack_3",
  "Arm_Dog|Crouch_Idle"
];

class AnimationManager {
  constructor() {
    this.timeLine = [];
    this.animations = [];
    this.currentAnimation = 0;
    this.idleAnimation = null;
    this.mixer = null;
  }

  init(mixer, animations) {
    this.animations = [...animations];
    this.mixer = mixer;

    for (let clip of this.animations) {
      this.mixer.clipAction(clip);
    }
  }

  addTimeline(animationNames) {
    this.timeLine = animationNames;
  }

  setIdle(animationName) {
    const idleClip = THREE.AnimationClip.findByName(
      this.animations,
      animationName
    );

    this.idleAnimation = this.mixer.clipAction(idleClip);

    this.idleAnimation.play();
  }

  findClip(animationName) {
    const clip = THREE.AnimationClip.findByName(this.animations, animationName);

    return clip;
  }

  play(animationName) {
    const clip = this.findClip(animationName);

    const action = this.mixer.clipAction(clip).setLoop(THREE.LoopOnce);

    this.mixer.addEventListener("finished", (e) => {
      this.idleAnimation.play();
    });

    this.idleAnimation.fadeOut(0.2).reset().stop();

    action.fadeIn(0.2).reset().play();
  }

  playTimeline() {}
}

const am = new AnimationManager();

function Husky({ position = [0, 0, 0], rotation = 0, path }) {
  const [model, setModel] = useState();
  const [mixerRef, setMixer] = useState(null);
  const [progress, setProgress] = useState(0);

  const { gl, scene, camera, raycaster } = useThree();

  const ref = useRef(null);

  function AnimationButton({ clip }) {
    // const animationButtons = animations.map((clip, index) => {
    return <button onClick={() => am.play(clip.name)}>{clip.name}</button>;
    // });

    // return animationButtons;
  }

  useFrame((_state, delta) => {
    if (!mixerRef) return;

    mixerRef.update(delta * 1);
  });

  useEffect(() => {
    const huskyManager = new THREE.LoadingManager();
    const backgroundManager = new THREE.LoadingManager();
    const loader = new GLTFLoader(huskyManager);
    const roughnessMipmapper = new RoughnessMipmapper(gl);
    const pmremGenerator = new THREE.PMREMGenerator(gl);
    const rgbeLoader = new RGBELoader(backgroundManager);

    huskyManager.onStart = function (url, itemsLoaded, itemsTotal) {
      // console.log(url, itemsLoaded, itemsTotal);
    };

    huskyManager.onLoad = function (url, itemsLoaded, itemsTotal) {
      console.log("🐺 Woof");
    };

    huskyManager.onProgress = function (url, itemsLoaded, itemsTotal) {
      const percentLoaded = (itemsLoaded / itemsTotal) * 100;
      // console.log("loading", percentLoaded);
      setProgress(percentLoaded);
    };

    pmremGenerator.compileEquirectangularShader();

    rgbeLoader
      .setDataType(THREE.UnsignedByteType)
      .setPath("/textures/")
      .load("winter_lake_01_1k.hdr", (texture) => {
        const envMap = pmremGenerator.fromEquirectangular(texture).texture;

        scene.environment = envMap;

        texture.dispose();
        pmremGenerator.dispose();
      });

    loader.setPath("/models/husky_dog/").load(
      "scene.gltf",
      (model) => {
        const rootScene = model.scene;
        const mixer = new THREE.AnimationMixer(rootScene);
        const clips = model.animations;

        am.init(mixer, clips);

        am.setIdle("Arm_Dog|idle_1");

        // console.log(am.animations);

        setMixer(mixer);

        rootScene.scale.set(0.1, 0.1, 0.1);

        rootScene.rotateY(THREE.Math.degToRad(rotation));

        rootScene.position.x = position[0];
        rootScene.position.y = position[1];
        rootScene.position.z = position[2];

        // if the object can cast a shadow, let it cast and receive a shadow
        rootScene.traverse((child) => {
          if (child.isMesh) {
            roughnessMipmapper.generateMipmaps(child.material);
            child.castShadow = true;
          }
        });

        setModel(rootScene);
      },
      () => {},
      (error) => {
        console.error("GLTF loading error:", error);
      }
    );
  }, []);

  return (
    <mesh>
      {progress < 100 && (
        <mesh position={[0, 8, 0]}>
          <Html center>
            <Logo
              animate={{ scale: [0.8, 1] }}
              transition={{ repeat: Infinity, duration: 0.5 }}
            >
              <svg viewBox="0 0 1000 1000">
                <path
                  d="M851.08 309v195.86c0 109.14-89.48 197.93-199.46 197.93a199.63 199.63 0 01-140.2-57.28A213.66 213.66 0 00559 552.84a104.54 104.54 0 0092.61 55.7c57.7 0 104.48-46.42 104.48-103.68V279.62a94.63 94.63 0 0124.73-63.92 399.6 399.6 0 00-561.66 0 94.63 94.63 0 0124.73 63.92v225.23c0 57.26 46.78 103.68 104.48 103.68 54.44 0 99.19-41.33 104.07-94.08.3-3.17-.15-16 0-19.2A196.18 196.18 0 01500 376.41a200.24 200.24 0 01239.34-49.26v121.43a104.56 104.56 0 00-87.72-47.4c-54.44 0-99.18 41.33-104.06 94.08-.29 3.17.15 16 0 19.2A196.19 196.19 0 01500 633.31a199.69 199.69 0 01-151.62 69.48c-110 0-199.46-88.79-199.46-197.93V309a397.86 397.86 0 00-48.54 191c0 220.71 178.91 399.62 399.62 399.62S899.62 720.71 899.62 500a397.86 397.86 0 00-48.54-191zM441 456.88a104.86 104.86 0 00-180.47-8.1V327.2a200.34 200.34 0 01228.06 37A213.66 213.66 0 00441 456.88z"
                  fill="#ffffff"
                />
              </svg>
            </Logo>
          </Html>
        </mesh>
      )}
      {model && <primitive castShadow ref={ref} object={model}></primitive>}
      {/* <Html>
        {am.animations.length &&
          am.animations.map((clip, idx) => {
            return <AnimationButton key={`animation-${idx}`} clip={clip} />;
          })}
      </Html> */}
    </mesh>
  );
}

export default Husky;
