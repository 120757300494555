import React, { useEffect } from "react";
import { useThree, useResource, useFrame } from "react-three-fiber";

// we need to keep track of the last thing the camera was looking
// at for the animation, might be a better way to do this in the
// long run, but this works
let _cam;

const Camera = ({ position, lookAt }) => {
  const { setDefaultCamera, scene } = useThree();

  const [camRef, camera] = useResource();

  // Make the camera known to the system as the default camera
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => void setDefaultCamera(camRef.current), []);

  useFrame(() => camera.updateProjectionMatrix());

  useEffect(() => {
    if (!camera) return;
    // console.log(camRef.current)
    camera.lookAt(lookAt);
  }, [lookAt]);

  // initital loaded position for the scene
  useEffect(() => {
    let cam = camRef.current;
    _cam = camRef.current;
    window._cam = cam;
  }, [camRef]);

  return (
    <perspectiveCamera position={position} ref={camRef}>
      {/* we're attaching lights to the camera so we can better see what 
      is being looked at */}

      {/* <pointLight
        position={[0.5, 0, 0.866]}
        args={["#ffffff", 2, 200, 2]}
        // args={["#ffffff", 0.8 * Math.PI]}
      /> */}

      {/* the camera helper can be useful when developing to see what it's looking at */}
      {/* {camera && <cameraHelper args={[camera]} />} */}
    </perspectiveCamera>
  );
};

function _useCamera() {
  return _cam;
}

export const useCamera = _useCamera;

export default Camera;
